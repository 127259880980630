import React from "react";
import { Link } from "react-router-dom";

import "./index.css"

export default function NavLogo() {
  return (
      <Link to="/" className="navbar-logo">
        <div className="logo-container">
          BERURA
          <div className="logo-division">IT</div>
        </div>
    </Link>
    );
}
