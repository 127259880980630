import React, { useState, useEffect } from 'react'
import Button from '../Button';
import NavLogo from './NavLogo';
import NavItem from './NavItem';
import { Link } from "react-router-dom";

import "./index.css";

export default function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [activeMenuItem, setActiveMenuItem] = useState("legal-notice");
 

  const menuItems: Array<{value: string, name: string, to: string}> = [
    // {value: "Startseite", name: "home", to: "/"},
    // {value: "Dienstleistungen", name: "services", to: "/services"},
    {value: "Impressum", name: "legal-notice", to: "/"},
  ];
  
  const showButton = () => {
    if(window.innerWidth < 958) {
      setButton(false);
    } else {
      setButton(true);
    }
  }

  const onClick_MobileMenuIcon = (e: React.MouseEvent<HTMLDivElement, MouseEvent>)  => {
    setClick(!click);
  }

  const onClick_MobileHomeItem = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setClick(false);
    setActiveMenuItem(e.currentTarget.id);
  };

window.onresize = showButton;

useEffect(() => {
  showButton();
}, [])

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <NavLogo />
          <div className="menu-icon" onClick={(e) => onClick_MobileMenuIcon(e)}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          {menuItems.map((item) => {
            return <NavItem key={item.name} id={item.name} className={`${item.name}${activeMenuItem === item.name ? " active" : ""}`} to={item.to} value={item.value} onClick={(e) => onClick_MobileHomeItem(e)} />;
          })}
        </ul>
        {/* {button &&
          <Link to="/sign-up">
            <Button buttonStyle='btn--outline'>Kundencenter</Button>
          </Link>} */}
      </nav>
    </>
  )
}

