import React from "react";
import { Link } from "react-router-dom";

import "./index.css"

interface Props {
  value: string,
  id: string,
  className: string;
  to: string,
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
}

export default function NavItem(props: Props) {
  return (
       <li className='nav-item' >
            <Link
              id={props.id}
              to={props.to}
              className={`nav-links ${props.className}`}
              onClick={(e) => props.onClick(e)}
            >
              {props.value}
            </Link>
          </li>
    );
}
