import React, { useState, useEffect } from 'react';
import './App.css';
import { Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './sites/Home';
import Services from './sites/Services';
import Contact from './sites/Contact';
import LegalNotice from './sites/LegalNotes';
import SignUp from './sites/SignUp'

function App() {

  return (
    <div className="App">
      <header id="header">
        <Navbar />
      </header>
      <main id="main">
        <Routes>
          <Route index element={<LegalNotice/>}/>
          <Route path="/" element={<LegalNotice/>}/>
          {/* <Route path="/" element={<Home/>}/>
          <Route path="/services" element={<Services/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/legal-notice" element={<LegalNotice/>}/>
          <Route path="/sign-up" element={<SignUp/>}/> */}
        </Routes>
      </main>
      <footer id="footer">
        © 2020 - 2022 BERURA
      </footer>
    </div>
  );
}

export default App;
